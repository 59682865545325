
import './App.scss';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import View from './views/Default';
import { Tab, Tabs } from './components/Tabs/Tabs';

function App() {

  return (
    <Router>
      <div className='app'>
        <Switch>
          <Route path="/admin" >
              <View admin />
          </Route>
          
          {/* <Route path="/tabs" >
            <div className="view">
              <Tabs>
                <Tab id="bovaljare" title="Bostadsväljare">
                  <View />

                </Tab>
                <Tab id="solstudie" title="Solstudie">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/2BZtVp7Birw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laudantium hic aperiam error eum? Reiciendis, dolorum ullam hic sequi labore quos quo, suscipit nam dolore sapiente doloremque nostrum nihil, molestias aspernatur!
                </Tab>
                <Tab id="solstudie2" title="Solstudie2">
                    Solstudien2
                </Tab>
                <Tab id="solstudie3" title="Solstudie3">
                    Solstudien3
                </Tab>
              </Tabs>
              Vy med tabbar
            </div>
          </Route> */}

          <Route path="/" >
            <div className="view">
              <View />
            </div>
          </Route>
          
        </Switch>
      </div>
    </Router>

  );
}

export default App;
