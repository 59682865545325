import React, { useState } from 'react';
import { useEffect } from 'react';
import Hotspots from './Hotspots/Hotspots';
import Zoom from './Zoom/Zoom';
import Loader from '../Loader/Loader';
import Filter from './Filter/Filter';
import List from './List/List';
import './ImageView.scss';
import AreaOptions from './AreaOptions/AreaOptions';
import EditArea from './EditArea/EditArea';
import { sortData, filterItems } from '../../functions/methods';
import axios from 'axios';
import {useDrag, useGesture } from '@use-gesture/react';
import {useSpring, animated} from '@react-spring/web';


const ImageView = ({data, activeImg, admin=false, ...props}) => {
    const [loading, setLoading] = useState(true);
    const [imgLoaded, setImageStatus] = useState(false);
    const [viewBox, setViewbox] = useState("0 0 0 0");
    const [mousePos, updateMousePos] = useState({ x: 0, y: 0 });
    const [activeHouseID, setActiveHouseID] = useState(false);
    const [hoverHouseID, setHoverHouseID] = useState(false);
    const [sortOn, setSortVal] = useState('title');
    const [dataItems, setDataItems] = useState(false);
    const [ip, setIP] = useState('');
    const [filterValues, setFilterValues] = useState(false);
    const [options, updateOptions] = useState({
                                        filter: {
                                            visible: false, 
                                            active: false
                                        }, 
                                        list: {
                                            visible: false,
                                            active: false
                                        },
                                    });

    const [imageZoom, updateImageZoom] = useState(1);
    const [imageMove, updateImageMove] = useState({ x: 0, y: 0 });
    const [sizes, updateSizes] = useState({
                                    wrapperWidth: 0,
                                    pageWidth: 0,
                                    wrapperHeight: 0,
                                    pageHeight: 0,
                                    diff: {
                                        x: 0,
                                        y: 0
                                    }
                                });
    
    let countImages = Object.keys(data.project.images).length;
    const [{ x, y, scale }, api] = useSpring(() => ({ x: 0, y: 0, scale: imageZoom }))


    const bind = useDrag(({ down, offset: [ox, oy] }) => api.start(
        { x: imageZoom > 1 ? ox: 0, y: imageZoom > 1 ? oy: 0, scale: imageZoom, immediate: down }), 
        {
            bounds: { left: -(sizes.wrapperWidth / 3 * imageZoom), right: (sizes.wrapperWidth / 3 * imageZoom), top: -(sizes.wrapperHeight / 3 * imageZoom), bottom: (sizes.wrapperHeight / 3 * imageZoom) },
            rubberband: true,

        }
    )

    useEffect(()=>{
        if(data.items){
            sortData(data.items, sortOn).then(function (sortedList) {
                // console.log('ressortedListult', sortedList);
                filterItems(data.items, filterValues, sortedList, handleFilterResults);
            })
        }
        else {
            setDataItems([])
        }
    }, [data.items])
    // },[])
    // },[dataItems])
    // },[activeImg && data.items.length !== dataItems.length])

    useEffect(()=>{

        if (!imgLoaded) {
            // Create new image to be able to get set the svg viewbox correctly
            var img = new Image();
            img.src = data.project.images[activeImg];

            img.onload = function () {
                // console.log('image loaded');
                setViewbox(`0 0 ${img.width} ${img.height}`);
                setLoading(false);
                setImageStatus(true);
                if (props.setImageSize){
                    props.setImageSize({width: img.width, height: img.height})
                }

                setTimeout(function () {
                    calcAreaDimensions();
                }, 500)
            }
        }
    })


    const getIpAdress = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        setIP(res.data.IPv4)
    }

    useEffect(() => {
        //passing getData method to the lifecycle method
        getIpAdress();
        
    }, [])

    useEffect(()=>{
        // console.log('- - - - - - - - -');
        // console.log('sizes.pageHeight ' + sizes.pageHeight);
        // console.log('document.body.offsetHeight ' + document.body.offsetHeight);
        if (imgLoaded && (sizes.pageHeight !== document.body.offsetHeight)) {
            calcAreaDimensions();    
        }
    // }, [sizes.pageHeight !== document.body.offsetHeight])
    })
    // },[imgLoaded])

    
    function calcAreaDimensions(){
        let _areaWrapper = document.getElementById('area');
        let _areaData = _areaWrapper.getBoundingClientRect();
        let diff = {};
        diff.x = _areaData.x + window.scrollX;
        diff.y = _areaData.y + window.scrollY;

        updateSizes({
            wrapperWidth : _areaWrapper.offsetWidth,
            pageWidth : document.body.offsetWidth,
            wrapperHeight : _areaWrapper.offsetHeight,
            pageHeight : document.body.offsetHeight,
            diff: {
                x : isNaN(diff.x) ? 0 : diff.x,
                y : isNaN(diff.y) ? 0 : diff.y
            }
        })
    }

    function highlightAreas(e){

        let target = e.currentTarget;

        if (!target.classList.contains('mouse-is-moving')) {
            target.classList.add('mouse-is-moving');
        }

        let timeout = setTimeout(function () {
            if (target.classList.contains('mouse-is-moving')) {
                target.classList.remove('mouse-is-moving');
                clearTimeout(timeout);

            }
            // console.log('not moving');

        }, 3000);
    }

    async function viewNextImage() {

        let nextImgID = parseInt(activeImg.replace('image', ''), 16);
        if (countImages - nextImgID === 0) {
            nextImgID = 1;
        }
        else {
            nextImgID += 1;
        }

        nextImgID = "image" + nextImgID;
        setLoading(true);
        setImageStatus(false);

        props.updateActiveImage(nextImgID);
    }

    const getStyles = () => (
        {
            transform: 'translateZ(0) translate(' + x + 'px, ' + y + 'px) scale(' + imageZoom + ')',
            transition: 'transform .2s ease'
        }
        // {
        //     transform: 'translateZ(0) translate(' + imageMove.x + 'px, ' + imageMove.y + 'px) scale(' + imageZoom + ')',
        //     transition: 'transform .2s ease'
        // }
    )

    function updateSpecificOptions(option, type, value){
        let optionsCopy = {...options};
        optionsCopy[option][type] = value;
        updateOptions(optionsCopy);
    }

    function filterResults(items){
        setDataItems(items);
    }

    function updateFilterValues(values){
        setFilterValues(values)
    }

    async function updateSortValue(val){
        setSortVal(val);

        await sortData(dataItems, val).then(sortedList => {
            // if(filterValues){
                filterItems(dataItems, filterValues, sortedList, handleFilterResults);
            // }
            // else {
            //     filterResults(sortedList)
            // }

        });
    }


    function handleFilterResults(status, result) {
        filterResults(result);
    }

    function clearFilter() {
        console.log('clear filter');
        setFilterValues(false)
        updateSortValue(sortOn)
    }

    function handleHouseClick(_id){
        setActiveHouseID(_id)

        if(props.onHouseClick && _id){
            props.onHouseClick(_id.replace('text-',''), ip)
        }
        
    }
    

    return (
        
        <div className={`${options.filter.active ? "filter-on " : "" }"relative"`}>
            {dataItems && !loading ?
            <>
                {!admin &&
                    <>
                    {data.project.list && 
                    <List 
                        options={options}
                        project={data.project} 
                        activeImg={activeImg} 
                        items={dataItems} 
                        loading={loading}
                        clearFilter={clearFilter}
                        updateSortValue={updateSortValue} 
                        viewNextImage={viewNextImage} 
                        updateOptions={updateSpecificOptions} />
                    }

                    <Filter 
                        items={data.items} 
                        sortOn={sortOn} 
                        details={options.filter} 
                        updateFilterValues={updateFilterValues} 
                        filterValues={filterValues} 
                        filterResults={filterResults} 
                        updateOptions={updateSpecificOptions}/>
                    </>
                }

                <div id="area" {...bind()} className={data.project.reverseButtons ? "image-wrapper reverse" : "image-wrapper"} >
                    <animated.img style={{x,y, scale}}  src={data.project.images[activeImg]} alt="" />

                    <AreaOptions 
                        admin={admin}
                        listView={data.project.list}
                        countImages={countImages} 
                        updateOptions={updateSpecificOptions}
                        viewNextImage={viewNextImage} />
                    
                    {!admin &&
                        <Zoom imageZoom={imageZoom} updateImageZoom={updateImageZoom} updateImageMove={updateImageMove} />
                    }

                    {admin &&
                        <EditArea 
                            viewBox={viewBox} 
                            mousePos={mousePos} 
                            items={data.items}
                            updateSizes={calcAreaDimensions}
                            activeImgID={activeImg}
                            zoom={imageZoom}
                            sizes={sizes}
                            projectID={props.projectID}
                            updateMousePos={updateMousePos} />
                    }

                    
                    <Hotspots 
                        highlightAreas={highlightAreas}
                        setHoverHouseID={setHoverHouseID}
                        updateMousePos={updateMousePos}
                        setActiveHouseID={handleHouseClick}
                        items={dataItems}
                        project={data.project}
                        sizes={sizes}
                        activeImg={activeImg}
                        viewBox={viewBox}
                        mousePos={mousePos}
                        hoverHouseID={hoverHouseID}
                        activeHouseID={activeHouseID}
                        admin={admin}
                        projectID={props.projectID}
                        styles={{x,y, scale}}
                    />

                    

                  
                </div>
            </>
            :
            <Loader />
            }
        </div>
    );
};

export default ImageView;