import React from 'react';
import './Hotspots.scss'
import ItemsInfo from '../ItemsInfo/ItemsInfo';
import { getStatusClass } from '../../../functions/methods';
import { animated } from '@react-spring/web';

const Hotspots = ({ items, project, styles, activeImg, sizes, viewBox, highlightAreas, setHoverHouseID, updateMousePos, setActiveHouseID, mousePos, hoverHouseID, activeHouseID, ...props}) => {

    function updateInfoPosition(_x, _y) {
        let mouseX = _x - sizes.diff.x;
        let mouseY = _y - sizes.diff.y;
        const infoSize = { w: 250, h: 250 };

        if (mouseX > (sizes.pageWidth - sizes.diff.x - infoSize.w)) {
            mouseX = mouseX - (infoSize.w / 1.5);
        }
        else if (mouseX < infoSize.w) {
            mouseX = mouseX + (infoSize.w * 0.25);
        }

        if (mouseY > (sizes.pageHeight - sizes.diff.y * 2 - infoSize.h)) {
            mouseY = mouseY - infoSize.h;
        }

        if (isNaN(mouseX) || isNaN(mouseY)) {
            return false;
        }

        mouseX = isNaN(mouseX) ? 0 : mouseX;
        mouseY = isNaN(mouseY) ? 0 : mouseY;

        updateMousePos(
            {
                x: mouseX,
                y: mouseY
            }
        );
    }

    function hoverHouse(e, id) {
        // console.log('hover hus');
        setHoverHouseID("text-" + id);

        let _x = e.pageX;
        let _y = e.pageY;

        updateInfoPosition(_x, _y);

    }

    function clickHouse(id) {
        setActiveHouseID("text-" + id);
        // console.log('klick hus', activeHouseID);

    }

    function mouseOutInfo() {
        // console.log('musen rör sig ut');
        setHoverHouseID(false);
    }

    

    return (
        <div className="hotspots" onMouseMove={highlightAreas}>
            <animated.svg style={styles} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox={viewBox}>
                {
                    Object.keys(items).map((area, key) => {
                        let _points = items[area].points ? items[area].points[activeImg] : null;
                        const status = getStatusClass(items[area].status);
                        if(!_points)return false;
                        return (
                            <g key={'area_' + key} id={'area_' + items[area].id} className={status}>
                                <polygon points={_points}
                                    onMouseMove={(e) => hoverHouse(e, items[area].id)}
                                    onClick={(e) => clickHouse(items[area].id)}
                                    onMouseLeave={mouseOutInfo} />
                            </g>
                        )

                    })
                }
            </animated.svg>

            <ItemsInfo
                items={items}
                project={project}
                mousePos={mousePos}
                hoverHouseID={hoverHouseID}
                activeHouseID={activeHouseID}
                admin={props.admin}
                projectID={props.projectID}
                setActiveHouseID={setActiveHouseID}
                activeImgID={activeImg}
            />
        </div>
    );
};

export default Hotspots;