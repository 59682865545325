import React, { Component } from 'react';
import firebase from '../../config/firebase';
import './ContactForm.scss';

class ContactForm extends Component {

  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);

    this.state = {
      name:"",
      email: "",
      phone: "",
      statusOk:false,
      loading:false
    }
  }

  handleFormChange(e){
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleSubmit(e){
    e.preventDefault();
    console.log('submitting', JSON.stringify(this.state));
    this.setState({
      loading:true
    });
    
    this.sendEmail();

  }

  async sendEmail(){
    const dest = this.props.email;
    const { name, email, phone } = this.state;
    const objectName = this.props.objectName;
    const projectName = this.props.projectName;

    const details = {
      dest : dest,
      name : name,
      email : email,
      phone : phone,
      objectName : objectName,
      projectName : projectName
    }


      // firebase.functions().useEmulator("localhost", 5001);
      const sendEmail = firebase.functions().httpsCallable('sendEmail');

      sendEmail(details)
        .then((result) => {
          console.log('then 1');
          this.setState({
            statusOk: true,
            loading: false
          })
        })
        .catch((err) => {
          console.error(err)
          this.setState({
            statusOk: false,
            loading: false
          })
        });
  }

  render() {

    return (
      <form onSubmit={this.handleSubmit} className={this.state.statusOk ? "contact-form form-submitted" : "contact-form " }>
      <h3>Fyll i formuläret nedan</h3>

        <input name="name"  type="name"  onChange={this.handleFormChange} value={this.state.name} placeholder="Namn" required />
        <input name="phone" type="tel" onChange={this.handleFormChange} value={this.state.phone} placeholder="Telefonnummer" required />
        <input name="email" type="email" onChange={this.handleFormChange} value={this.state.email} placeholder="E-post" required />
        <button className={this.state.loading ? "waiting-response" : ""} type="submit" value="Skicka">Skicka</button>
      </form>
    );
  }
}

export default ContactForm;
