import React, { Component } from 'react';

import './ImageUploader.scss';

class ImageUploader extends Component {

  constructor(props){
    super(props);

    this.imgSrc = (this.props.imgSrc) ? this.props.imgSrc : "https://via.placeholder.com/300x300";
    this.label = (this.props.label) ? this.props.label : "Bild";

    this.handleChange = this.handleChange.bind(this);
    this.removeImage = this.removeImage.bind(this);

    this.state = ({
      blob: null
    })
  }

  componentDidMount(){

  }

  componentWillUpdate(nextProps, nextState){

    if(nextProps.imgSrc){

      this.imgSrc = nextProps.imgSrc;
      // this.props.onUpload(nextProps.files[0]);

    }
  }

  dataURLToBlob(dataURL) {
    var BASE64_MARKER = ';base64,',
    parts,
    contentType,
    raw;

    if (dataURL.indexOf(BASE64_MARKER) === -1) {
        parts = dataURL.split(',');
        contentType = parts[0].split(':')[1];
        raw = parts[1];

        return new Blob([raw], {type: contentType});
    }

    parts = dataURL.split(BASE64_MARKER);
    contentType = parts[0].split(':')[1];
    raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], {type: contentType});
  }

  handleChange(e){

    // var preview = document.querySelector('img');
    var preview = e.target.previousElementSibling;
    var file    = e.target.files[0];
    var blob;

    var reader = new FileReader();

    reader.addEventListener("load", function (readerEvent) {
      // preview.src = reader.result;

      var image = new Image();
      image.onload = function (imageEvent) {

         // Resize the image
         var canvas = document.createElement('canvas'),
             max_size = 2400,// TODO : pull max size from a site config
             width = image.width,
             height = image.height;
         if (width > height) {
             if (width > max_size) {
                 height *= max_size / width;
                 width = max_size;
             }
         } else {
             if (height > max_size) {
                 width *= max_size / height;
                 height = max_size;
             }
         }
         canvas.width = width;
         canvas.height = height;
         canvas.getContext('2d').drawImage(image, 0, 0, width, height);
         var dataUrl = canvas.toDataURL('image/jpeg');
         var resizedImage = this.dataURLToBlob(dataUrl);

         blob = resizedImage;

         blob.name = file.name;

     }.bind(this)

     image.src = readerEvent.target.result;

     preview.src = image.src;

   }.bind(this), false);

   if (file) {
     reader.readAsDataURL(file);

   }

   reader.addEventListener("loadend", function() {

       var blobCheck = setInterval(function(){

         if(blob){
           this.props.onUpload(this.props.id, blob);
           clearInterval(blobCheck);
         }

       }.bind(this), 100);

    }.bind(this));

  }

  removeImage(){
    console.log('ta bort denna: ', this.props.id);
    this.props.removeImage(this.props.id.split('project-image-')[1]);
  }

  render() {
    return (
      <div className={"image-uploader " + this.props.className } id={this.props.id}>
        <img alt="upload" src={this.imgSrc} />
        <input onChange={this.handleChange} type="file" />
        <label>{this.label}</label>
        {!this.props.mainImage &&
          <div className="remove-image icon-close" onClick={this.removeImage}></div>
        }
      </div>
    );
  }
}

export default ImageUploader;
