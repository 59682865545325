import {getExternalData, saveProjectItems} from './Queries';

async function updateProjectData(projectID, fbData, callback){

    console.log('FROM METHODS: ', getExternalData(projectID));
    getExternalData(projectID).then(function (externalData){
        // console.log('externaldata', externalData.items)
    // saveProjectItems(projectID, externalData.items);
        callback(externalData.items);

    });


   
}

async function replaceProjectData(projectID, fbData, callback){

    const externalData = await getExternalData(projectID);

    // console.log( externalData.items)

   
}

const getRandomId = ()=>{
    return Math.random().toString(36).substr(2, 9);
}

async function sortData(items, prop) {
    if(!items) return false;

    let sortedItems = await Object.keys(items).sort(function (a, b) {
        var nameA, nameB = "";
        if (!items[a][prop] || !items[b][prop]) {
            nameA = items[a].info[prop].text; // ignore upper and lowercase
            nameB = items[b].info[prop].text; // ignore upper and lowercase
        }
        else {
            nameA = items[a][prop].toUpperCase(); // ignore upper and lowercase
            nameB = items[b][prop].toUpperCase(); // ignore upper and lowercase
        }

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }

        // names must be equal
        return 0;
    });

    return sortedItems;
}

async function filterItems(items, filterValues, filterItems, callback) {

    let filterInactive = true;

    const sortedObjectIds = filterItems.filter((key, index, data) => {
        const _item = items[key];
        const _roomsTitle = _item.info.rooms.title;
        const _rooms = _item.info.rooms.text;
        const _status = _item.status;

        let _statusNiceName = _status;
        switch (_status) {
            case "available":
            case "Tillgänglig":
            case "Till salu":
            case "till-salu":
            case "Tillgängligt":
                _statusNiceName = 'Tillgängliga';
                break;
            case "reserved":
            case "Reserverad":
            case "Reserverat":
                _statusNiceName = 'Reserverade';
                break;
            case "Visningsbostad":
            case "Visning":
                _statusNiceName = 'Visningsbostad';
                break;
            case "sold":
            case "Såld":
            case "Sålt":
                _statusNiceName = 'Sålda';
                break;
            default:
                _statusNiceName = _status;
                break;


        }
        // console.log('status: ', _status, _statusNiceName);
        if(!filterValues) return true;

        const valuesObj = filterValues[_roomsTitle];
        const statusesObj = filterValues['Status'];

        let allValuesInactive = Object.keys(valuesObj).every(function (k) { return valuesObj[k] === false });
        let allStatusesInactive = Object.keys(statusesObj).every(function (k) { return statusesObj[k] === false });

        filterInactive = (allValuesInactive && allStatusesInactive) ? true : false;

        let filterResult = (
            (valuesObj[_rooms] && statusesObj[_statusNiceName]) ||
            (valuesObj[_rooms] && allStatusesInactive) ||
            (statusesObj[_statusNiceName] && allValuesInactive) ||
            (allValuesInactive && allStatusesInactive));

        return filterResult;

    });

    let filterStatus = !filterInactive ? true : false;

    let sortedObjects = {};

    sortedObjectIds.map((val) => {
        sortedObjects[val] = items[val];
        return true;
    });

    // console.log('sorted', sortedObjects);

    callback(filterStatus, sortedObjects)

    // this.setState({
    //     filterOn: filterStatus,
    //     objects: sortedObjects
    // })
}

const getDateStrings = () => {


    let _today = new Date();
    let _year = _today.getFullYear(),
        _month = _today.getMonth() + 1,
        _date = _today.getDate(),
        _time = _today.getHours().toString() + _today.getMinutes().toString() + _today.getSeconds().toString();

    _month = _month < 10 ? "0"+ _month : _month;
    _date = _date < 10 ? "0"+ _date : _date;

    let dateStr = _year + '-' + _month + '-' + _date;
    let compressesedDateStr = _year.toString() + _month.toString() + _date.toString() + _time;

    return {date: dateStr, compressedStr: compressesedDateStr};
}

const getStatusClass = (status) => {
    status = status.toLowerCase();

    switch (status) {
        case "Reserverad":
        case "reserverad":
            return "reserved";
        case "Såld":
        case "såld":
            return "sold";
        case "Visningsbostad":
        case "visningsbostad":
        case "Visning":
        case "visning":
            return "display";
        case "Tillgänglig":
        case "tillgänglig":
        case "Till salu":
        case "till salu":
            return "available";
        case "Kommande":
        case "kommande":
            return "upcoming";
        default:
            return status;
    }
}

export {
    sortData,
    filterItems,
    updateProjectData,
    getRandomId,
    getDateStrings,
    getStatusClass
}