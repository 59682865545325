import React, { useState } from 'react';
// import { Button } from '../../framework/Elements';
// import { IconClose } from '../../framework/Icons';
import './Modal.scss';

const Modal = ({title, button, linkImage, linkText="Klicka här", children}) => {
    const [modalOpen, toggleModal] = useState(false);

    function handleModalClick(){
        toggleModal(!modalOpen);
    }

    const classNames = modalOpen ? 'popup--open' : 'popup';


    return (
        <>
            {button ?
                <div onClick={handleModalClick}>
                    {button.length > 0 ? button : linkText}
                </div>
            :
            linkImage ?
                <div className='popup__trigger' onClick={handleModalClick}>
                    <img src={linkImage} alt="Open Popup" />
                </div>
                :
                <div className={'popup__trigger'} onClick={handleModalClick}>
                    {linkText}
                </div>
            }

            <div className={classNames} onClick={handleModalClick}>
                
                <div className={'popup__content'} onClick={(e) => e.stopPropagation()}>
                    <div className={'popup__header'}>
                        {title && 
                        <>
                            {title}
                        </>
                        }
                        <div className={'icon icon-close popup__close'} onClick={handleModalClick}>

                        </div>
                    </div>
                    <div className={'popup__main'} >
                        {modalOpen &&
                        <>
                            {children}
                        </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;