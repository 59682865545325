import React from 'react';
import './Button.scss';

const Button = ({ onClick, ...props }) => {

    function handleClick(e) {
        const target = e.target;
        target.classList.add('clicked');
        onClick();

        setTimeout(function () {
            target.classList.remove('clicked');
        }, 500);
    }

    return(
        <div className={`btn${props.icon ? '--icon' : ''}${props.className ? ' ' + props.className : ''}`} onClick={handleClick}>
            {props.icon &&
                <i className={`icon-${props.icon}`}></i>
            }
            {props.text || props.children}
            
        </div>
    )
}

export default Button;

