import React from 'react';
import './Textarea.scss';

const Textarea = ({onChange, ...props}) => {

    function handleChange(e){
        const target = e.target;
        var val = target.value;

        let prop = (props.id) ? props.id : target;
        console.log('change text: ', prop, val);
        onChange(prop, val);
    }

    return (
        <div className="input-wrapper">
            <label className={(props.noLabel) ? 'hidden' : ''} htmlFor={'input-' + (props.id)}>{props.label}</label>

            <textarea name={'input-' + (props.id)}
                placeholder={props.label || ''}
                onChange={handleChange}
                value={props.value}
            >
            </textarea>
        </div>
    );
};

export default Textarea;