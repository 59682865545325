import React from 'react';
import './AreaOptions.scss';

const AreaOptions = ({countImages=0, viewNextImage, updateOptions, listView, admin}) => {
    function toggleList(){
        updateOptions('list', 'visible', true)
    }

    function toggleFilter(){
        updateOptions('filter', 'visible', true)
    }
    return (
        <div className="area-options">
            <div onClick={viewNextImage} className={(countImages > 1) ? "btn--small btn--icon" : "hidden"}><i className="fas fa-sync-alt"></i> Rotera vy</div>
            {!admin &&
            <>
                {listView &&
                    <div onClick={toggleList} className="btn--small btn--icon"><i className="fas fa-th-list"></i> Visa lista</div>
                }
                <div onClick={toggleFilter} className="btn--small btn--icon"><i className="fas fa-sliders-h"></i> Filter</div>
            </>
            }
        </div>
    );
};

export default AreaOptions;