import react from 'react';
import { useLocation } from 'react-router-dom';
import strings from '../config/lang.json';

// function useQuery() {
//     return new URLSearchParams(useLocation().search);
// }

const StringResolver = (str, fallback) => {
    // let query = useQuery();
    // const lang = query.get("lang");
    const lang = document.documentElement.lang;

    if (!strings[str] || (strings[str] === undefined) || !strings[str][lang] || (strings[str][lang] === undefined)) return fallback || "[Felaktig sträng]"

    return (
        strings[str][lang]
    );
};

export {StringResolver};