import firebase from 'firebase';
import "firebase/auth";
import "firebase/storage";
import "firebase/database";
import "firebase/functions";


import firebaseConfig from './fbConfig';
// const firebaseConfig = {
//     apiKey: "AIzaSyBo8yIWUE9UBfiU1OhEIu2QtgY2T_xbCAg",
//     authDomain: "test-1c6eb.firebaseapp.com",
//     databaseURL: "https://test-1c6eb.firebaseio.com",
//     projectId: "test-1c6eb",
//     storageBucket: "test-1c6eb.appspot.com",
//     messagingSenderId: "60602324384",
//     appId: "1:60602324384:web:0e335effff3f936d1b00c5"
// };

var fire = firebase.initializeApp(firebaseConfig.config);

export default fire;