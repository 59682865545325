import React from 'react';
import './ItemController.scss';
import Input from '../Input/Input';
import FileUploader from '../FileUploader/FileUploader';
import Textarea from '../Textarea/Textarea';
import Button from '../Button/Button';
import { useState } from 'react';
import firebase from '../../config/firebase';
import { getRandomId } from '../../functions/methods';
import { infoFields } from '../../config/initialData';
import { useEffect } from 'react';



const ItemController = ({...props}) => {
    const [editing, toggleEditing] = useState(false);
    const [itemData, setItemData] = useState(props.data.items[props.objectID]);

    // console.log('props', props);
    function handleSaveClick(){
        toggleEditing(false);
        props.onSave();
        props.projectRef.child('items').child(props.objectID).set(itemData)
    }

    function handleStatusChange(e){
        let val = e.target.value;

        let newData = {...itemData};
        newData.status = val;

        toggleEditing(true);
        setItemData(newData);

    }

    function handleTextChange(id, val){
        toggleEditing(true);

        // console.log('changing text: ', props.objectID, id, val);

        let newData = {...itemData};


        if (newData[id] !== undefined) {
            newData[id] = val;
            // console.log('itemData[id]', newData[id]);
        }
        else if (newData.info[id] !== undefined) {
            newData.info[id].text = val;
            // console.log('andra');
        }
        else {
            newData[id] = val;
        }

        // console.log('final data: ', newData);

        setItemData(newData);

    }

    function uploadFile(id, file){

        let fileName = id + "-" + getRandomId() + "-" + file.name;
        console.log('laddar upp fil', fileName);

        var storageRef = firebase.storage().ref();
        var fileFolderRef = storageRef.child('documents/' + fileName);

        fileFolderRef.put(file).then(function (snapshot) {
            console.log('Uploaded a blob or file!', snapshot);
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
                console.log('downloadURL: ', downloadURL);
                let newData = {...itemData};
                newData.link = downloadURL;
                setItemData(newData);
                toggleEditing(true);
            })
        });
    }

    const countTotalClicks = (users)=>{

        let total = 0;
        if (users) {
            Object.keys(users).map((userId) => {
                total += users[userId].clicks;
                return true;
            })
        }
        return total;
    }

    if(!props.objectID){
        return false;
    }
    else {
    
        const status = itemData.status.toLowerCase();

        return (

            <div className="object-view active">


                <div objectid={props.objectID} className="object-editor flex-col" disabled={(props.data.project.externalData && props.user.role !== 'admin')}>

                    <span className="date">
                        {itemData.date ?
                        <>
                            Skapad {itemData.date}
                        </>
                        :
                        <>
                            Externt synkad
                        </>
                        }
                    </span>

                    <h3 className={props.user.role === "admin" ? "hidden" : ""}>{itemData.title}</h3>
                    <div className="clicks">
                        <span>Antal unika klick: {itemData.users ? Object.keys(itemData.users).length : '0'}</span>
                        <span>Totalt antal klick: {countTotalClicks(itemData.users)}</span>
                    </div>
                    <div className="flex-row align-top object-editor__options">
                        <div className="flex-col">

                            <div >
                                <Input id="title" type="text" label="Titel" onChange={handleTextChange} value={itemData.title} />
                                <Textarea id="text" label="Infotext" onChange={handleTextChange} value={itemData.text} />

                                {!props.data.project.externalData &&

                                <div className="input-wrapper">
                                    <Input id="link" type="text" label="Länk" onChange={handleTextChange} value={itemData.link} />
                                    <FileUploader id={props.objectID} onUpload={uploadFile} label="Ladda upp ett nytt dokument" />
                                </div>
                                }

                            </div>

                        </div>
                        <div className="flex-row flex-1">
                            <div className="flex-row flex-1">
                                {
                                    infoFields.map((item, key) => (
                                        <Input key={key} id={item.id} type={item.type} label={item.title} onChange={handleTextChange} value={itemData.info[item.id].text} />
                                    ))
                                }
                            </div>

                            <div id="object-status" className="flex-row radio-buttons">
                                <label >
                                    <input
                                        onChange={handleStatusChange}
                                        value="available" type="radio"
                                        checked={status === "available" || status === "tillgänglig" || status === "till salu"}
                                        name="object-status" />
                                    Tillgänglig</label>
                                <label >
                                    <input
                                        onChange={handleStatusChange}
                                        value="reserved" type="radio"
                                        checked={status === "reserved" || status === "reserverad"}
                                        name="object-status" />
                                    Reserverad</label>
                                <label >
                                    <input
                                        onChange={handleStatusChange}
                                        value="sold" type="radio"
                                        checked={status === "sold" || status === "såld"}
                                        name="object-status" />
                                    Såld</label>
                                <label >
                                    <input
                                        onChange={handleStatusChange}
                                        value="upcoming" type="radio"
                                        checked={status === "upcoming" || status === "kommande"}
                                        name="object-status" />
                                    Kommande</label>
                            </div>
                            {props.data.project.interest &&
                            <Input id="email" type="text" label="Ansvarig mäklare" onChange={handleTextChange} value={itemData.email || props.data.project.email} />
                            }

                        </div>

                    </div>


                    <div className={(editing) ? '' : 'hidden'}>
                        <Button onClick={handleSaveClick} text="Spara" />
                    </div>

                </div>
            </div>
        );
    }
};

export default ItemController;