import React from 'react';
import './Button.scss';

const IconButton = ({icon, ...props}) => {
    const Content = () => (
        <>
        <i className={`icon-${icon}`}></i>
        {
        props.children &&
            <div className="icon-button__info">
                {props.children}
            </div>
        }
        </>
    )
    return (
        <>
        { props.href ?
            <a className={`icon-button ${props.className ? ' ' + props.className : ''}`} href={props.href}>
                <Content />
            </a>
        :
        <div className={`icon-button ${props.className ? ' ' + props.className : ''}`} onClick={props.onClick}>
            <Content />
        </div>
        }
        </>
    );
};

export default IconButton;