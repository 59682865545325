
const firebaseConfig = {
    apiKey: "AIzaSyAa5oNIz36wvOCN4f5St6IcZQOPNtpOqoI",
    authDomain: "bostadsvaljare-master-797a1.firebaseapp.com",
    projectId: "bostadsvaljare-master-797a1",
    storageBucket: "bostadsvaljare-master-797a1.appspot.com",
    messagingSenderId: "60800171520",
    appId: "1:60800171520:web:564d7ae54b676ff41822e5",
    measurementId: "G-Y19MG5F6DE"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyBo8yIWUE9UBfiU1OhEIu2QtgY2T_xbCAg",
//   authDomain: "test-1c6eb.firebaseapp.com",
//   databaseURL: "https://test-1c6eb.firebaseio.com",
//   projectId: "test-1c6eb",
//   storageBucket: "test-1c6eb.appspot.com",
//   messagingSenderId: "60602324384",
//   appId: "1:60602324384:web:0e335effff3f936d1b00c5"
// };

exports.config = firebaseConfig;