import React, { Component } from 'react';

import './FileUploader.scss';

class FileUploader extends Component {

  constructor(props){
    super(props);

    this.imgSrc = (this.props.imgSrc) ? this.props.imgSrc : "https://via.placeholder.com/300x300";
    this.label = (this.props.label) ? this.props.label : "Fil";

    this.handleChange = this.handleChange.bind(this);



  }

  handleChange(e){
    var file    = e.target.files[0];
    var reader = new FileReader();

    reader.addEventListener("load", function (readerEvent) {


    }, false);

     if (file) {
       reader.readAsDataURL(file);

       console.log('file: ', file);

     }

     reader.addEventListener("loadend", function() {
       this.props.onUpload(this.props.id, file);


    }.bind(this));

  }

  render() {
    return (
      <div className="file-uploader " id={this.props.id}>
        <label>{this.label}</label>
        <input onChange={this.handleChange} type="file" />
      </div>
    );
  }
}

export default FileUploader;
