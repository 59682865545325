import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Button from '../../Button/Button';
import './EditArea.scss';
import { updateProjectItem } from '../../../functions/Queries';

const EditArea = ({ active, ...props}) => {
    const vb = {x: props.viewBox.split(' ')[2], y:  props.viewBox.split(' ')[3]};

    const [dots, setDots] = useState([]);
    const [points, setPoints] = useState("");
    const [mousePos, updateMousePos] = useState({ x: 0, y: 0 });

    const [areaActive, toggleArea] = useState(false);
    const [panelOpen, togglePanel] = useState(false);
    const [chosenObjectID, updateObjectID] = useState(null);

    let currentPoints = (props.points) ? props.points[props.activeImgID] : null;
    // console.log('dots:', dots);
    const setKeyboardEvents = (e) => {

        switch (e.keyCode){
            // esc
            case 27: 
                cleanArea();
                break;
            //backspace, delete
            case 46: 
            case 8: 
                removeLastDot();
                break;
            
            default: 
                break;
            
        }
    }

    useEffect(()=>{
        document.addEventListener("keydown", setKeyboardEvents);
         return () => {
            document.removeEventListener("keydown", setKeyboardEvents);

        };
    },[dots, points])

    useEffect(()=>{
        // if(props.sizes.pageHeight !== document.body.offsetHeight){

        // }
        props.updateSizes();
    }, [props.sizes.pageHeight !== document.body.offsetHeight])


    function addNewPoint(){
        let newPoints = points;
        let newDots = dots;

        if (dots.length > 0) {
            let firstDot = document.getElementById('dot-0');

            if (firstDot.classList.contains('hover')) {
                togglePanel(true);

                return false;
            }
        }

        newPoints += ' ' + mousePos.x + ',' + mousePos.y;

        var newDot = {};

        newDot.x = mousePos.x;
        newDot.y = mousePos.y;
        newDots.push(newDot);

        setPoints(newPoints);
        setDots(newDots);
    }

    function cleanArea(){
        setDots([]);
        setPoints("");
        toggleArea(false);
        togglePanel(false);
        updateObjectID(false);
    }

    function removeLastDot(){
        const newPoints = points.substring(0,points.lastIndexOf(' '));
        setDots(dots.slice(0,dots.length - 1));
        setPoints(newPoints);
        togglePanel(false);
    }



    function addNewSvg(){
        if (props.zoom > 1) {
            alert('Du kan inte lägga till nya ytor när du är inzoomad');
            return false;
        }
        toggleArea(!areaActive);
    }

    function saveArea(){

        if (chosenObjectID) {
            let newItemData = {...props.items};
            newItemData[chosenObjectID].points = newItemData[chosenObjectID].points || {};
            newItemData[chosenObjectID].points[props.activeImgID] = points;
            updateProjectItem(props.projectID, chosenObjectID, newItemData[chosenObjectID]);
            cleanArea();
        }
        else {
            alert('Du måste välja vilket objekt som ska kopplas till ytan');
            
            return false;
        }
        
    }

    function handleOptionChange(e){

        let val = e.target.value;

        if (val === "null") {
            val = null;
        }

        updateObjectID(val);
    }

    function handleMouseMovement(e){

        let _x = (e.pageX - props.sizes.diff.x);
        let _y = (e.pageY - props.sizes.diff.y);

        _x = _x * (vb.x / props.sizes.wrapperWidth);
        _y = _y * (vb.y / props.sizes.wrapperHeight);

        const firstDot = dots ? dots[0] : false;

        if (firstDot !== undefined) {

            let firstDotEl = document.getElementById('dot-0');

            if (((_x - firstDot.x < 20) && (firstDot.x - _x < 20)) &&
                ((_y - firstDot.y < 20) && (firstDot.y - _y < 20))) {
                firstDotEl.classList.add('hover')
            }
            else {
                firstDotEl.classList.remove('hover')
            }
        }

        updateMousePos({x:_x, y:_y});
    }

    if(!props.items){
        return false;
    }
    else {

        return (
            <div onMouseMove={handleMouseMovement}>
                <div className={areaActive  ? "new-area editable" : "new-area hidden"} >
                    <svg version="3.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" onClick={addNewPoint}
                        viewBox={props.viewBox}>
                        <polygon id={"this.props.svgId"} points={currentPoints} />
                        <div className="icon icon-close" onClick={cleanArea}></div>
                        <polygon id={"activePoints"} points={points} />

                        {
                            dots.map((dot, index) => (
                                <circle key={'circle-' + index} id={"dot-" + (index)} className="dot" cx={dot.x} cy={dot.y} r="4" />
                            ))
                        }
                    </svg>
                    <div className={(panelOpen) ? "panel active" : "panel"}>
                        <div className="icon-close" onClick={cleanArea}></div>

                        <div className={(panelOpen) ? "" : "hidden"}>
                            <div className="select-wrapper">
                                <select onChange={handleOptionChange} value={chosenObjectID || ""}>
                                    <option value="null">Välj objekt</option>

                                    {
                                        Object.keys(props.items).map((object, key) => {
                                            let _availability = false;
                                            if (props.items[object].points) {
                                                _availability = props.items[object].points[props.activeImgID] ? true : false;
                                            }

                                            return (
                                                <option key={object} value={object} disabled={_availability}>
                                                    {props.items[object].title}
                                                </option>
                                            )

                                        })
                                    }
                                </select>
                            </div>
                            <div className="flex-row">
                                <Button
                                    onClick={saveArea}
                                    text="Spara"
                                />
                                <Button
                                    onClick={cleanArea}
                                    text="Rensa"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="edit-panel">
                    <Button
                        onClick={addNewSvg}
                        text="Lägg till"
                    />
                </div>

            </div>
        );
    }
};

export default EditArea;