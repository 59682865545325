
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import './Checkbox.scss';

const Checkbox = ({...props}) => {
    const [checked, setChecked] = useState(props.checked || false);

    function handleChange(e){
        const target = e.target;
        var val = target.checked;

        let prop = (props.id) ? props.id : target;

        props.onChange(prop, val);
    }

    useEffect(()=>{
        setChecked(props.checked);
    },[props.checked])

    return (
        <label className="checkbox-wrapper">

            <input
                type="checkbox" name={'checkbox-' + (props.id)}
                value="0"
                checked={checked}
                onChange={handleChange}
            />

            {props.label}

        </label>
    );
};

export default Checkbox;




