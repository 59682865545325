import React from 'react';
import './Zoom.scss';

const Zoom = ({ imageZoom, updateImageZoom, updateImageMove}) => {

    function zoomOnClick(_dir) {
        let _scale = imageZoom;



        if (_dir === '+') {
            _scale += .5;
            _scale = (_scale > 4) ? 4 : _scale;

        }
        else {
            _scale -= .5;
            _scale = (_scale < 1) ? 1 : _scale;

            if (_scale === 1) {
                const _moveImage = { x: 0, y: 0 }
                updateImageMove(_moveImage);
            }

        }

        updateImageZoom(_scale);


    }

    return (
        <div className="zoom-buttons">
            <div onClick={(e) => zoomOnClick('+')} className="btn zoom-in"><i className="fas fa-plus"></i></div>
            <div onClick={(e) => zoomOnClick('-')} className="btn zoom-out"><i className="fas fa-minus"></i></div>
        </div>
    );
};

export default Zoom;