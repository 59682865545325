import React, { useEffect } from 'react';
import './Login.scss';
import firebase from '../../config/firebase';

const Login = ({onLogin, user, onLogout}) => {

    useEffect(() => {
        firebase.auth().onAuthStateChanged(function (usr) {
            if (!usr.isAnonymous) {
                // User is signed in.

                // console.log('run function');
                onLogin(usr);
                // this.props.handleUser(user);

            } else {
                // No user is signed in.
                console.log('NO USER SIGNED IN');
                onLogout();

            }
        });
    },[!user])

    function handleLogin(e){
        e.preventDefault();

        var email = document.getElementById('email').value;
        var password = document.getElementById('password').value;
        let errorText = document.getElementById('error-message');

        firebase.auth().signInWithEmailAndPassword(email, password).catch(function (error) {
            // Handle Errors here.
            // var errorCode = error.code;
            var errorMessage = error.message;

            errorText.textContent = (errorMessage);

            errorText.classList.add('show');

            setTimeout(function () {
                errorText.classList.remove('show');

            }, 3000);
        });
    }

    function handleLogout(){
        firebase.auth().signOut().then(function () {
            // Sign-out successful.
            console.log('utloggad');
        }, function (error) {
            // An error happened.
        });
    }

    return (
        <>
        {!user ?
            <>
            <h2>Bostadsväljaren</h2>
            <form className="login-wrapper flex-col box" onSubmit={handleLogin}>

                <input required id="email" type="email" placeholder="Email" />
                <input required id="password" type="password" placeholder="password" />
                <input type="submit" value="Logga in" />
                <span id="error-message"></span>

            </form>
            </>
        :
            <div className='user-info wrapper'>
                <span>{user.userName}</span>
                <span className="email">{ user.email }</span>
                <span className="role">{ user.role }</span>
                <div className="btn--small" onClick={handleLogout}>Logga ut</div>
            </div>
 
        }
        </>
    );
};

export default Login;