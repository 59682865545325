import React from 'react';
import FilterView from './FilterView/FilterView';
import './Filter.scss';
import { sortData, filterItems } from '../../../functions/methods';
import { useEffect } from 'react';

const Filter = ({items, details, updateOptions, filterResults, filterValues, updateFilterValues, sortOn}) => {
    // const [showFilter, setFilter] = useState(false);

    const getFilterValues = () => {

        let roomValues = [];
        let filterObj = {};

        if (!items) {
            return filterObj;
        }

        Object.keys(items).map((itemId, index) => {

            const thisItem = items[itemId];
            const roomsAvailable = thisItem.info.rooms.text;

            if (index === 0) {
                filterObj[thisItem.info.rooms.title] = {}
            }

            if (roomValues.indexOf(roomsAvailable) === -1) {

                filterObj[thisItem.info.rooms.title][roomsAvailable] = false;

                roomValues.push(roomsAvailable);
            }
            return true;
        })


        filterObj["Status"] = {
            "Tillgängliga": false,
            "Reserverade": false,
            "Sålda": false,
        }


        return filterObj;

    }

    useEffect(()=>{
        if (!filterValues) {
            clearFilter();
        }
    })
    

    
    function toggleFilter(forcedVal) {
        let showFilterNext = !details.visible;

        if (forcedVal === 'hide') {
            showFilterNext = false;
        }
        else if (forcedVal === 'show') {
            showFilterNext = true;
        }

        // setFilter(showFilterNext)
        // toggleFilterVisibility(showFilterNext)
        updateOptions('filter', 'visible', showFilterNext)


    }

    function clearFilter(){
        updateFilterValues( getFilterValues() );
        toggleFilter('hide');
        filterList();
    }

    function handleFilterResults(status, result){
        updateOptions('filter', 'active', status);
        filterResults(result);
    }


    function filterList(filterType, id, val) {

        let newFilterValues = {...filterValues};
        if (filterType) {
            newFilterValues[filterType][id] = val;
        }
        else {
            newFilterValues = getFilterValues()
        }

        sortData(items, sortOn).then(sortedList => {
            filterItems(items, newFilterValues, sortedList, handleFilterResults);

        });

        updateFilterValues(newFilterValues)

    }

    return (
        <div className={details.visible ? 'modal show' : 'modal hide'} onClick={toggleFilter}>
            <div className="icon icon-close" ></div>

            <div className="filter" onClick={(e) => { e.stopPropagation() }}>

                {
                    Object.keys(filterValues).map((type) => {
                        let values = filterValues[type];

                        return (
                            <FilterView key={'filter-' + type} title={type} toggleFilter={toggleFilter} onChange={filterList} filterValues={values} />
                        )
                    })
                }
                <div className="filter__footer">
                    <div onClick={toggleFilter} className="btn--small">Visa resultat</div>
                    {details.active &&
                    <div onClick={clearFilter} className="btn--small">Rensa filter</div>
                    }
                </div>
            </div>

        </div>
    );
};

export default Filter;