import React from 'react';
import './Input.scss';

const Input = ({value, type="text", onChange, ...props}) => {

    function handleChange(e) {
        const target = e.target;
        var val = target.value;

        let prop = (props.id) ? props.id : target;

        onChange(prop, val);
    }

    return (
        <div className="input-wrapper">
            <label className={(props.noLabel) ? 'hidden' : ''} htmlFor={'input-' + (props.id)}>{props.label}</label>

            <input
                type={type} name={'input-' + (props.id)}
                placeholder={props.label}
                min="0"
                value={value}
                onChange={handleChange}
            />
            <div onClick={props.onSave} className={(props.onSave) ? 'btn--small' : 'hidden'}>Spara</div>

        </div>
    );
};

export default Input;
