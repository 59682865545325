import React from 'react';
import ImageSlider from '../../ImageSlider/ImageSlider';
import ContactForm from '../../ContactForm/ContactForm';
import { useState } from 'react';
import '../../../styles/utils/_colors.scss';
import './ItemsInfo.scss';
import { useEffect } from 'react';
import Loader from '../../Loader/Loader';
import firebase from '../../../config/firebase';
import { getStatusClass } from '../../../functions/methods';

import { StringResolver } from '../../../functions/StringResolver';
import Modal from '../../Modal';

const ItemsInfo = ({ items, project, mousePos, hoverHouseID, activeHouseID, setActiveHouseID, ...props }) => {
    const [formOpen, setFormOpen] = useState(false);
    const infoFields = ["price", "fee", "rooms", "property", "living_space", "yard_area", "operating_cost", "biarea", "floor"];

    useEffect(() => {
        // console.log('activehouseid', activeHouseID);

    }, [activeHouseID])

    function handleCloseClick() {
        setActiveHouseID(false);
    }

    function toggleForm(e) {
        e.preventDefault();
        let id = e.target.getAttribute('data-id');
        setFormOpen(id);
    }

    function removeArea(itemID) {
        console.log('ta bort yta på ' + props.projectID);
        console.log('area: ', itemID);
        console.log('activeImg', props.activeImgID);

        const fbRef = firebase.database().ref();
        const projectRef = fbRef.child('projects').child(props.projectID);
        projectRef.child('items').child(itemID).child('points').child(props.activeImgID).set(null)
        setActiveHouseID(false);

    }

    const getInfoFields = (area) => {
        const objects = items[area];
        return (
            <ul>

                {
                    infoFields.map((info, _key) => {
                        let _text = objects.info[info] ? objects.info[info].text : false;
                        // if(_text.length > 0 && _text != '-'){
                        if (_text && _text.length > 0) {
                            return (
                                <li key={'info-list-2' + _key} title={StringResolver(info, objects.info[info].title)}><span>{_text}</span></li>
                            );
                        }
                        return false;
                    })
                }


            </ul>
        );
    }

    const getStatus = (area) => {
        // const areaStatus = items[area].status.replaceAll(' ','-').toLowerCase();
        const areaStatus = items[area].status.toLowerCase();
        const areaClass = getStatusClass(areaStatus);

        let _string = '';

        switch (areaStatus) {
            case "available":
                // _string = 'Tillgänglig';
                _string = StringResolver(areaStatus);
                break;
            case "booked":
            case "reserved":
                // _string = 'Reserverad';
                _string = StringResolver(areaStatus);
                break;
            case "sold":
                // _string = 'Såld';
                _string = StringResolver(areaStatus);
                break;
            case "upcoming":
                // _string = 'Kommande';
                _string = StringResolver(areaStatus);
                break;
            default:
                _string = items[area].status;
                break;
        }

        return (
            <span className={areaClass + ' status'}>
                {_string}
            </span>
        );
    }

    return (
        <>
            {items ?
                <div className="info-wrapper">
                    {
                        Object.keys(items).map((area, key) => {
                            let hoverInfoStyle = {
                                top: mousePos.y,
                                left: mousePos.x,
                                transition: 'transform .2s ease'
                            }
                            const itemInfo = items[area];
                            const extraClasses = itemInfo.hidePrice ? 'hide-price ' : '';

                            return (
                                <div key={'wrapper-' + key} className={extraClasses + (getStatusClass(itemInfo.status) === 'sold' ? 'item-sold' : '')}>
                                    <ul style={hoverInfoStyle} className={(hoverHouseID === 'text-' + items[area].id) ? 'show hover-info' : 'hover-info'}>
                                        <li className="list-title">{items[area].title}</li>
                                        <li className="list-status">{getStatus(area)}</li>
                                        

                                        {
                                            infoFields.map((info, _key) => {
                                                let _text = (items[area].info[info]) ? items[area].info[info].text : '';
                                                if (_text && _text.length > 0) {
                                                    return (

                                                        <li key={'hover-' + _key} title={StringResolver(info, items[area].info[info].title)}><span>{_text}</span></li>

                                                    );
                                                }
                                                return false;
                                            })
                                        }
                                    </ul>

                                    <div className={(activeHouseID === 'text-' + items[area].id) ? 'show info' : 'hide info'}>
                                        <ImageSlider />
                                        <div className="info__header">
                                            <h2>{items[area].title} </h2>
                                            <div className="icon icon-close" onClick={handleCloseClick}></div>
                                        </div>
                                        <div style={{flex: 1}}>
                                        {/* <div className="icon icon-close" onClick={handleCloseClick}></div>

                                <h2>{items[area].title} </h2> */}

                                        {itemInfo.text.length > 0 &&
                                            <p>{itemInfo.text}</p>
                                        }
                                        {getStatus(area)}

                                        {getInfoFields(area)}

                                        


                                        {project.interest &&
                                            <>
                                                <div className={formOpen === items[area].id ? "" : "hidden"}>

                                                    <ContactForm
                                                        projectName={project.title}
                                                        objectName={items[area].title}
                                                        email={items[area].email || project.email}
                                                    />

                                                </div>
                                                <a className={formOpen === items[area].id ? "disabled btn--small" : "btn--small"} data-id={items[area].id} onClick={toggleForm} href={"mailto:" + project.email}>{StringResolver("interest")}</a>
                                            </>
                                        }
                                        {items[area].link &&
                                            <a className="btn--small" target="_blank" rel="noreferrer" href={items[area].link}>
                                                {project.layout === "boklok" ?
                                                <>
                                                    Till bostaden
                                                </>
                                                :
                                                <>
                                                    { StringResolver("floor_plan") }
                                                    <img alt="Planlösning" src="../media/ritning.gif" className="pdf-icon" />
                                                </>
                                                }

                                            </a>
                                        }
                                        {props.admin &&
                                            <div className="btn--small btn-delete"
                                                onClick={(e) => removeArea(area)}>
                                                Ta bort yta
                                            </div>
                                        }
                                        </div>
                                        
                                        {items[area].floorplan &&
                                        
                                            <div className="floor-plan">
                                                <Modal linkImage={items[area].floorplan}>
                                                    <img alt="Planlösning" src={items[area].floorplan} />
                                                </Modal>
                                                {/* <a href={items[area].floorplan} target="_blank" rel="noreferrer"><img alt="Planlösning" src={items[area].floorplan} /></a> */}
                                            </div>
                                        }

                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                :
                <Loader />
            }
        </>

    );
};

export default ItemsInfo;