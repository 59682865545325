import React from 'react';
import { useEffect } from 'react';
import Checkbox from '../../../Checkbox/Checkbox';

import './FilterView.scss';

const FilterView = ({filterActive, value="", type="text", ...props}) => {
    const prevStrId = 'filter-checkbox-';

    useEffect(()=>{
        // console.log('filtervalues', props.filterValues);

    },[props.filterValues])

    const getOptions = () => {
        if (props.options) {
            return (
                <div className="flex-row filter__options">
                    <div onClick={cleanFilter} className={filterActive ? "btn--small" : "hidden"}>Rensa</div>
                </div>
            )
        }
    }

    function cleanFilter() {
        Object.keys(props.filterValues).map((value, key) => {
            handleChange(value, false);
            return true;
        })
    }

    function handleChange(id, val) {

        let filterValId = id.replace(prevStrId, '');
        props.onChange(props.title, filterValId, val);
    }
    
    return (
        <div className={filterActive ? "filter__list" : "filter__list filter__list--inactive"}>
            <div className="filter__list__header">
                <h3>{props.title}</h3>
            </div>

            {
                Object.keys(props.filterValues).map((value, key) => {
                    let checked = props.filterValues[value];
                    return (
                        <Checkbox key={prevStrId + value} id={prevStrId + value} checked={checked} label={value} onChange={handleChange} />
                    )
                })


            }

            {getOptions()}

        </div>
    );
};



export default FilterView;


// import React, { Component } from 'react';




// class Filter extends Component {

//     constructor(props) {
//         super(props);

//         value = (props.value) ? props.value : "";
//         type = (props.type) ? props.type : 'text';
//         checked = (props.checked) ? props.checked : false;
//         showFilter = (props.showFilter) ? props.showFilter : false;

//         prevStrId = 'filter-checkbox-';

//         state = ({
//             filterIsActive: props.isActive
//         })

//         handleChange = handleChange.bind(;
//         cleanFilter = cleanFilter.bind(;
//         getOptions = getOptions.bind(;
//     }

//     UNSAFE_componentWillUpdate(props, state) {

//         let checkedVal = false;

//         Object.keys(props.filterValues).map((value, key) => {
//             let checked = props.filterValues[value];
//             if (checked) {
//                 checkedVal = checked;
//             }
//             if (checked && !state.filterIsActive) {
//                 setState({
//                     filterIsActive: true
//                 })
//             }
//             return true;
//         });

//         if (!checkedVal && state.filterIsActive) {
//             setState({
//                 filterIsActive: false
//             })
//         }


//     }
//     handleChange(id, val) {

//         let filterValId = id.replace(prevStrId, '');
//         props.onChange(props.title, filterValId, val);
//     }

//     cleanFilter() {
//         Object.keys(props.filterValues).map((value, key) => {
//             handleChange(value, false);
//             return true;
//         })
//     }
//     getOptions() {
//         if (props.options) {
//             return (
//                 <div className="flex-row filter__options">
//                     <div onClick={cleanFilter} className={state.filterIsActive ? "btn--small" : "hidden"}>Rensa</div>
//                 </div>
//             )
//         }
//     }

//     render() {

//         let _classes = state.filterIsActive ? "filter__list" : "filter__list filter__list--inactive";

//         return (

//             <div className={_classes}>
//                 <div className="filter__list__header">
//                     <h3>{props.title}</h3>
//                 </div>

//                 {
//                     Object.keys(props.filterValues).map((value, key) => {
//                         let checked = props.filterValues[value];
//                         return (
//                             <Checkbox key={prevStrId + value} id={prevStrId + value} checked={checked} label={value} onChange={handleChange} />
//                         )
//                     })


//                 }

//                 {getOptions()}

//             </div>

//         )
//     }
// }

// export default Filter;
