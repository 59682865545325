
import firebase from '../config/firebase';

export async function getAllusers(callback) {

    await firebase.database().ref('users').on('value', function (snap) {
        const val = snap.val();
        // console.log('val: ', val);
        callback(val)
        return val;
    }, (errorObject) => {
        console.log('The read failed: ' + errorObject.name);
    });

}

export async function getAllProjects(callback) {

    await firebase.database().ref('projects').on('value', function (data) {
        const val = data.val();
        // console.log('val: ', val);
        callback(val);

    }, (errorObject) => {
        console.log('The read failed: ' + errorObject.name);
    });

}

export function getUser(userID, callback) {


    const dbRef = firebase.database().ref();
    dbRef.child("users").child(userID).get().then((snapshot) => {
        if (snapshot.exists()) {
            // console.log(snapshot.val());
            callback(snapshot.val())
        } else {
            console.log("No data available");
            callback(false)
        }
    }).catch((error) => {
        console.error(error);
        callback(false)

    });

}

export function getProject(id, callback, handleLoad, onError) {

    // console.log('getProject id: ', id);

    firebase.database().ref(`projects/${id}`).on('value', function (snap) {
        const val = snap.val();
        // console.log('val: ', val);
        callback(val)
        if (handleLoad) {
            handleLoad(false);
        }
    }, (errorObject) => {
        console.log('The read failed: ' + errorObject.name);
        onError();
    });

}

export function saveProjectItems(projectID, items) {
    // console.log('save', items);
    firebase.database().ref(`projects/${projectID}/items`).set(items);
}

export function saveProjectItem(projectID, itemID, data) {
    firebase.database().ref(`projects/${projectID}/items/${itemID}`).set(data);
}

export function updateProjectItem(projectID, itemID, data) {
    const dbRef = firebase.database().ref();
    dbRef.child('projects').child(projectID).child("items").child(itemID).set(data);
}

// export async function saveProjectData(projectID) {
// }

export async function getExternalData(projectID) {
    // await new Promise(resolve => setTimeout(resolve, 1000));
    var myRequest = new Request('http://skaa09aap325tyinte.dxcloud.episerver.net/feed');
    let promise = new Promise((resolve, reject) => {
        fetch(myRequest).then(function (response) {
            // console.log('response: ', response);
            return response.json();
            // // Examine the text in the response
            // response.json().then(function (data) {
            //     console.log('data', data[projectID]);
            //     return data[projectID];
            // });

            // return response;
        }).then(function (data) {
            console.log('- - - - - - - - - - - - -');
            // console.log('data', data[projectID]);
            setTimeout(() => resolve(data[projectID]), 1000); // (*)
            // return Promise.resolve(data[projectID]);
        });
    });

    return promise;


}

