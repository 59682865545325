import { getDateStrings, getRandomId } from "../functions/methods";

const infoFields = 
    [
        {
            id: "price",
            title: "Pris"
        },
        {
            id: "fee",
            title: "Avgift"
        },
        {
            id: "rooms",
            title: "Antal rum",
            type: "number"
        },
        {
            id: "property",
            title: "Bostadstyp"
        },
        {
            id: "living_space",
            title: "Boarea"
        },
        {
            id: "biarea",
            title: "Biarea"
        },
        {
            id: "yard_area",
            title: "Tomtarea"
        },
        {
            id: "operating_cost",
            title: "Driftkostnad"
        }
    ];
    



const getInitialItemData = () =>{
    const dateStrings = getDateStrings();
    const _id = dateStrings.compressedStr + getRandomId();

    return ( 
        {
            id: _id,
            title: '-',
            date: dateStrings.date,
            text: '',
            available: true,
            status: "available",
            link: '',
            email: '',
            info: {
                property: {
                    title: "Bostadstyp",
                    text: "-"
                },
                living_space: {
                    title: "Boarea",
                    text: "-m²"
                },
                biarea: {
                    title: "Biarea",
                    text: "-"
                },
                yard_area: {
                    title: "Tomtarea",
                    text: "-"
                },
                rooms: {
                    title: "Antal rum",
                    text: "-"
                },
                price: {
                    title: "Pris",
                    text: "-"
                },
                operating_cost: {
                    title: "Driftkostnad",
                    text: "-"
                },
                fee: {
                    title: "Avgift",
                    text: "-"
                }
            }
        }
    )
};

export {
    infoFields,
    getInitialItemData
}